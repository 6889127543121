import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Fragment, React, useState } from "react";
import background from "../Img/ProductLines_2025.jpg";
import { FadeInSection } from "./Helpers/FadeinSection";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { EcoTwoTone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 270,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
    fontSize: 15,
    textAlign: "left",
  },
  coveragesBackground: {
    backgroundImage: `url(${background}) !important`,
    backgroundSize: "50% 100%",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  },
  coveragesBackgroundMobil: {
    backgroundImage: `url(${background}) !important`,
    backgroundSize: "100% 100%",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      color: "black",
      backgroundColor: "transparent",
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
    color: "black",
  },
  cardAction: {
    height: "80px",
  },
}));

const Coverages = () => {
  const classes = useStyles();
  const width = useMediaQuery("(min-width:800px)");
  const [expanded, setExpanded] = useState({
    a: false,
    b: false,
    c: false,
    d: false,
    e: false,
    f: false,
    g: false,
    h: false,
    i: false,
  });

  const handleExpandClick = (e, name, val) => {
    setExpanded({
      ...expanded,
      [name]: !val,
    });
  };

  return (
    <Fragment>
      <section id="coverages">
        <Grid container>
          <Grid item lg={6} sm={6} xs={12}>
            <h2 style={{ color: "color: rgb(19, 45, 79);" }}>Product Lines</h2>
          </Grid>
          <Grid item lg={6} xs={false}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <FadeInSection>
              <Grid container>
                <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                  <Card className={classes.root}>
                    <CardActions
                      onClick={(e) => handleExpandClick(e, "a", expanded.a)}
                      disableSpacing
                      className={classes.cardAction}
                    >
                      <Typography component="p" style={{ textAlign: "left" }}>
                        <b>Commercial Auto Liability</b>
                      </Typography>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded.a,
                        })}
                        aria-expanded={expanded.a}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>

                    <Collapse in={expanded.a} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          <div>
                            Protects you and your company by paying for bodily
                            injury or property damages you may become legally
                            liable for as a result of a covered automobile
                            accident.
                          </div>
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                  <Card className={classes.root}>
                    <CardActions
                      onClick={(e) => handleExpandClick(e, "b", expanded.b)}
                      disableSpacing
                      className={classes.cardAction}
                    >
                      <Typography component="p" style={{ textAlign: "left" }}>
                        <b>Physical Damage</b>
                      </Typography>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded.b,
                        })}
                        aria-expanded={expanded.b}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>
                    <Collapse in={expanded.b} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          <div>
                            Pays for damage to your vehicle from another vehicle
                            whether you hit another vehicle or object, another
                            vehicle hits you, or your vehicle rolls over –
                            regardless of who is at fault (subject to
                            deductible).
                            <br />
                            Provides coverage for damage to your vehicle from
                            most risks including fire and theft.
                          </div>
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </FadeInSection>
            <FadeInSection>
              <Grid container>
                <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                  <Card className={classes.root}>
                    <CardActions
                      onClick={(e) => handleExpandClick(e, "c", expanded.c)}
                      disableSpacing
                      className={classes.cardAction}
                    >
                      <Typography component="p" style={{ textAlign: "left" }}>
                        <b>Uninsured And Underinsured Motorist</b>
                      </Typography>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded.c,
                        })}
                        aria-expanded={expanded.c}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>
                    <Collapse in={expanded.c} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          <div>
                            Which pays for medical expenses, loss of income and
                            other damages owed to you, your employees (expenses
                            that are not covered by workers’ compensation) or
                            your passengers when an accident is caused by an
                            uninsured or underinsured motorist.
                          </div>
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                  <Card className={classes.root}>
                    <CardActions
                      onClick={(e) => handleExpandClick(e, "d", expanded.d)}
                      disableSpacing
                      className={classes.cardAction}
                    >
                      <Typography component="p" style={{ textAlign: "left" }}>
                        <b>Personal Injury Protection (PIP)</b>
                      </Typography>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded.d,
                        })}
                        aria-expanded={expanded.d}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>
                    <Collapse in={expanded.d} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          <div>
                            Pays basic expenses for an insured and his or her
                            family in states with no-fault auto insurance, such
                            as medical expenses, in the event of an accident.
                          </div>
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </FadeInSection>
            <FadeInSection>
              <Grid container>
                <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                  <Card className={classes.root}>
                    <CardActions
                      onClick={(e) => handleExpandClick(e, "e", expanded.e)}
                      disableSpacing
                      className={classes.cardAction}
                    >
                      <Typography component="p" style={{ textAlign: "left" }}>
                        <b>Motor Truck Cargo (MTC)</b>
                      </Typography>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded.e,
                        })}
                        aria-expanded={expanded.e}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>
                    <Collapse in={expanded.e} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          <div>
                            Provides Coverage for loss of Property in the course
                            of transit.
                          </div>
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                  <Card className={classes.root}>
                    <CardActions
                      onClick={(e) => handleExpandClick(e, "f", expanded.f)}
                      disableSpacing
                      className={classes.cardAction}
                    >
                      <Typography component="p" style={{ textAlign: "left" }}>
                        <b>Trailer Interchange</b>
                      </Typography>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded.f,
                        })}
                        aria-expanded={expanded.f}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>
                    <Collapse in={expanded.f} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          <div>
                            Provides Physical Damage / Comprehensive and
                            Collision for non- owned trailers being pulled under
                            a trailer interchange agreement.
                          </div>
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </FadeInSection>
            <FadeInSection>
              <Grid container>
                <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                  <Card className={classes.root}>
                    <CardActions
                      onClick={(e) => handleExpandClick(e, "g", expanded.g)}
                      disableSpacing
                      className={classes.cardAction}
                    >
                      <Typography component="p" style={{ textAlign: "left" }}>
                        <b>Excess Liability</b>
                      </Typography>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded.g,
                        })}
                        aria-expanded={expanded.g}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>
                    <Collapse in={expanded.g} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          <div>
                            It activates when that policy reaches its limit.
                            Provides additional coverage for your Auto liability
                            insurance policies.
                          </div>
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                  <Card className={classes.root}>
                    <CardActions
                      onClick={(e) => handleExpandClick(e, "h", expanded.h)}
                      disableSpacing
                      className={classes.cardAction}
                    >
                      <Typography component="p" style={{ textAlign: "left" }}>
                        <b>Excess Cargo</b>
                      </Typography>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded.h,
                        })}
                        aria-expanded={expanded.h}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>
                    <Collapse in={expanded.h} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          <div>
                            It activates when that policy reaches its limit.
                            Provides additional coverage for your Motor Truck
                            Cargo (MTC) insurance policies.
                          </div>
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </FadeInSection>
            <FadeInSection>
              <Grid container>
                <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                  <Card className={classes.root}>
                    <CardActions
                      onClick={(e) => handleExpandClick(e, "i", expanded.i)}
                      disableSpacing
                      className={classes.cardAction}
                    >
                      <Typography component="p" style={{ textAlign: "left" }}>
                        <b>General Liability</b>
                      </Typography>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded.i,
                        })}
                        aria-expanded={expanded.i}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>
                    <Collapse in={expanded.i} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          <div>
                            Insurance designed to protect business owners and
                            operators from a wide variety of liability
                            exposures.
                          </div>
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </FadeInSection>
          </Grid>
          <Grid item xs={6}>
            <img alt="Coverage" src={background} width="100%"></img>
          </Grid>
        </Grid>
      </section>
    </Fragment>
  );
};

export default Coverages;
