import { Fragment } from "react";
import About from "../About";
import BecomeProducer from "../BecomeProducer";
import Careers from "../Careers";
import Claims from "../Claims";
import Contact from "../Contact";
import Coverages from "../Coverages";
import GetQuote from "../GetQuote";
import Markets from "../Markets";
import OfficeOfCompliance from "../OfficeOfCompliance";
import PreferedVendors from "../PreferedVendors";
import LossControl from "../LossControl";
import LossRuns from "../LossRuns";
import Epay from "../Epay";
import NavBar from "../NavBar";
import NavBar1 from "../NavBar1";

const Main = () => {
  return (
    <Fragment>
      <NavBar1 />
      {/* <NavBar/> */}
      <Epay />
      <Coverages />
      <Markets />
      <About />
      <GetQuote />
      <BecomeProducer />
      <Contact />
      <OfficeOfCompliance />
      <Careers />
      <PreferedVendors />
      <Claims />
      <LossControl />
      <LossRuns />
    </Fragment>
  );
};
export default Main;
