import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import about1 from "../Img/AboutUs_2025.jpg";
// import state from "../Img/state.png";
import state from "../Img/ST States.png";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    color: "#838C95",
    padding: 10,
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
    fontSize: 15,
  },
  coveragesBackground: {
    // backgroundImage: `url(${background}) !important`,
    // backgroundSize: '50% 100%',
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  },
  coveragesBackgroundMobil: {
    // backgroundImage: `url(${background}) !important`,
    // backgroundSize: '100% 100%',
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  },
  media: {
    // 16:9
  },
  pos: {
    lineHeight: "20px",
  },
});

const About = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <section id="about">
        <h2>About Us</h2>
        <FadeInSection>
          <Grid container direction="row-reverse">
            <Grid item xs={12} md={7} lg={7} style={{ padding: 20 }}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography component="p" style={{ textAlign: "left" }}>
                    <b>Who we are</b>
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <p style={{ lineHeight: "25px", textAlign: "left" }}>
                      In 2021 STERN was formed by a group of insurance industry
                      executives each with over 30 years experience and various
                      disciplines.
                      <br />
                      <br />
                      STERN executives along with their group of experienced
                      staff and associates are committed to help provide the
                      markets and insurance coverages you need and desire.
                      <br />
                      <br />
                      At STERN we offer Products and Programs to help you write
                      your business and we are committed to building a long-term
                      business relationship with you.
                      <br />
                      <br />
                      The STERN team is driven to help all our clients succeed.
                      Using our cutting edge systems technology, our team will
                      always be available to help you. Once we receive a fully
                      completed submission and all the required information we
                      are extremely proficient at providing a quick turnaround.
                    </p>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={5} lg={5} style={{ paddingTop: 50, paddingBottom: 80 }}>
              <img src={about1} width={700}></img>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <h2>Currently Writing / Coming Soon</h2>
            <img src={state} width={1100}></img>
          </Grid>
        </FadeInSection>
      </section>
    </Fragment>
  );
};

export default About;
